import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    name: "404",
    meta: {
      title: "404",
    },
    component: () => import("../views/404.vue"),
  },
  {
    path: "/2025/99/login",
    name: "login",
    meta: {
      title: "登入",
    },
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/",
    name: "index",
    component: () => import("../views/IndexView.vue"),
    meta: {
      title: "",
    },
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
          title: "",
        },
      },

      {
        path: "/sysUserInfo",
        name: "sysUserInfo",
        component: () => import("../views/sys/SysUserInfoView.vue"),
        meta: {
          title: "管理员管理",
        },
      },
      {
        path: "/customerUser",
        name: "customerUser",
        component: () => import("../views/model/CustomerUser.vue"),
        meta: {
          title: "用户管理",
        },
      },
      {
        path: "/theme",
        name: "theme",
        component: () => import("../views/model/ThemeView.vue"),
        meta: {
          title: "题材管理",
        },
      },
      {
        path: "/config",
        name: "config",
        component: () => import("../views/model/ConfigView.vue"),
        meta: {
          title: "配置管理",
        },
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/model/NewsView.vue"),
        meta: {
          title: "热点管理",
        },
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

// 全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
  //如果路由需要跳转

  if (to.path != "/2025/99/login" && to.path != "/404") {
    if (localStorage.getItem("token") != null) {
      next(); //放行
    } else {
      // next()  //放行
      router.push("/404");
    }
  } else {
    // 否则，放行
    next();
  }
});

export default router;
