<template>
  <div class="main"></div>
</template>

<script>
import { send } from "@/api/api";
export default {
  name: "HomeView",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  destroyed() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  text-align: center;
  // width: 1500px;
  padding: 50px 100px 50px 100px;
  height: 700px;
}
</style>
